
import ConstUtils from '@/utils/ConstUtils.js';
import Vehicle from "@/domain/model/vehicle/Vehicle.js";

export default class VehicleEvents {
  
  constructor(eventsDomain) {
    this.eventsDomain = eventsDomain;
    this.domain = this.eventsDomain.domain;
  }
  
  contains(name) {
    if (name) {
      //
    }
    return false;
  }
  
  export(params) {
    var event = VehicleEvents.Export.Operation(params);
    return this.eventsDomain.initRequest(event);
  }

  exportEquipment(params) {
    var event = VehicleEvents.ExportEquipment.Operation(params);
    return this.eventsDomain.initRequest(event);
  }

  static ExportEquipment = {
    Names: {
      Request: 'ExportEquipmentQueryOperation',
      Response: 'ExportEquipmentQueryNotification',
    },

    Errors: {
      NotFound: {
        id: 'NotFound',
        msg: 'NotFound',
        details: ''
      },
    },

    Operation: function(data) {
      var event = {
        eventName: VehicleEvents.ExportEquipment.Names.Request,
        handler: VehicleEvents.ExportEquipment.Names.Request,
      }

      var params = {
        filter: data,
      }

      event["Params"] = params;
      return event;
    },
  }

  static Export = {
    Names: {
      Request: 'ExportVehiclesQueryOperation',
      Response: 'ExportVehiclesQueryNotification',
    },

    Errors: {
      NotFound: {
        id: 'NotFound',
        msg: 'NotFound',
        details: ''
      },
    },

    Operation: function(data) {
      var event = {
        eventName: VehicleEvents.Export.Names.Request,
        handler: VehicleEvents.Export.Names.Request,
      }

      var params = {
        filter: data,
      }

      event["Params"] = params;
      return event;
    },
  }

  list(filter) {
    var event = VehicleEvents.List.Request(filter);
    return this.eventsDomain.initRequest(event);
  }
  
  restore(vehicleId) {
    var event = VehicleEvents.Restore.Request(vehicleId);
    return this.eventsDomain.initRequest(event);
  }
  
  remove(vehicle) {
    var event = VehicleEvents.Remove.Request(vehicle);
    return this.eventsDomain.initRequest(event);
  }
  
  odometerSum() {
    var event = VehicleEvents.OdometerSum.Request();
    return this.eventsDomain.initRequest(event);
  }
  
  details(filter) {
    var event = VehicleEvents.List.Request(filter);
    return this.eventsDomain.initRequest(event);
  }
  
  static Save = {
    Names: {
      Request: 'VehicleSaveRequestEvent',
      Response: 'VehicleSaveResponseEvent',
    },

    Errors: {
      NotFound: {
        id: 'NotFound',
        msg: 'The specified vehicle does not exist',
        details: '',
      },
    },

    Request: function(company, vehicle) {
      var data = "";
      if (vehicle.id) {
        data = vehicle.data;
      } else {
        data = vehicle;
      }
      var companyId;
      if (company.id) {
        companyId = company.id();
      } else {
        companyId = company[ConstUtils.FIELDS.ID];
      }
      data['Company'] = companyId;
      var signInCmd = {
        Vehicle: data,
        handler: 'VehicleSaveRequestEvent',
        eventName: 'VehicleSaveRequestEvent',
      }
      return signInCmd
    },
  }
  
  static List =  {
    Names: {
      Request: 'VehicleListRequestEvent',
      Response: 'VehicleListResponseEvent',
    },

    Errors: {
      NotFound: {
        id: 'NotFound',
        msg: 'NotFound',
        details: '',
      },
    },

    Request: function(filter) {
      var event = {
          handler: "VehicleListRequestEvent",
          eventName: "VehicleListRequestEvent",
          filter: filter.data,
      };
      return event;
    },
  }
  
  static Remove = {
      Names: {
        Request: 'VehicleRemoveRequestEvent',
        Response: 'VehicleRemoveResponseEvent',
      },

      Errors: {
        NotFound: {
          id: 'NotFound',
          msg: 'The specified vehicle does not exist',
          details: '',
        },
      },

      Request: function(vehicle) {
        var data = "";
        if (vehicle.id) {
          data = vehicle.id();
        } else {
          data = vehicle[ConstUtils.FIELDS.ID];
        }
        var removeVehicleCmd = {
          Vehicle: data,
          handler: 'VehicleRemoveRequestEvent',
          eventName: 'VehicleRemoveRequestEvent',
        }
        return removeVehicleCmd
      },
    }
  
  static Restore = {
      Names: {
        Request: 'VehicleRestoreRequestEvent',
        Response: 'VehicleRestoreResponseEvent',
      },

      Errors: {
        NotFound: {
          id: 'NotFound',
          msg: 'The specified vehicle does not exist',
          details: '',
        },
      },

      Request: function(vehicleId) {
        var removeVehicleCmd = {
          Vehicle: vehicleId,
          handler: VehicleEvents.Restore.Names.Request,
          eventName: VehicleEvents.Restore.Names.Request,
        }
        return removeVehicleCmd
      },
    }
  
  static OdometerSum = {
      Names: {
        Request: 'VehiclesOdometerSumRequestEvent',
        Response: 'VehiclesOdometerSumResponseEvent',
      },

      Errors: {
        NotFound: {
          id: 'NotFound',
          msg: 'The specified stat does not exist',
          details: '',
        },
      },

      Request: function() {
        var removeVehicleCmd = {
          handler: VehicleEvents.OdometerSum.Names.Request,
          eventName: VehicleEvents.OdometerSum.Names.Request,
        }
        return removeVehicleCmd
      },
    }
  
  static Details = {
      Names: {
        Request: 'VehicleDetailsRequestEvent',
        Response: 'VehicleDetailsResponseEvent',
      },

      Errors: {
        NotFound: {
          id: 'NotFound',
          msg: 'The specified stat does not exist',
          details: '',
        },
      },

      Request: function(vehicle) {
        var details = {
          handler: VehicleEvents.Details.Names.Request,
          eventName: VehicleEvents.Details.Names.Request,
        }
        if (vehicle.data) {
          details[Vehicle.MODEL_NAME] = vehicle.id();
        } else {
          details[Vehicle.MODEL_NAME] = vehicle['@rid'];
        }
        return details
      },
    }
}