
import RcResponseEvent from '@/domain/model/events/RcResponseEvent.js';

export default class RcEventListener {
  
  constructor(domain, listener, context) {
    this.domain = domain;
    this.logger = domain.logger();
    this.listener = listener;
    this.context = context;
  }

  emit(event) {
    var responseEvent = this.domain.events().findByData(event);
    if (responseEvent == null) {
      responseEvent = new RcResponseEvent(this.domain, event);
    }
    //this.logger.info(JSON.stringify(responseEvent.data, null, 2));
    this.logger.info("Received Response {1} ({0}) {2}", [responseEvent.data['@rid'], responseEvent.data['eventName'], this.listener.name]);
    return this.listener(responseEvent, this.context);
  }
}